import constants from "@/constants";
import { DateTimeUtils } from "@/helpers/dateTimeUtils";
import { StringUtils } from "@/helpers/stringUtils";
import { FormUtils } from "@/helpers/formUtils";
import { UserService } from "@/services/userService";
import moment from "moment";
import { OrderService } from "@/services/orderService";
import routePaths from "@/router/routePaths";
import constPickupTime from "./constPickupTime";
import { StorageService } from "@/services/storageService";
// import { PartnerService } from '@/services/partnerService';
import { CommonService } from "@/services/commonService";
import { getTextTruncate } from "@/helpers/stringUtils.js"
const initItemOrder = {
  description: "",
  length: null,
  width: null,
  height: null,
  weight: null,
  orderValue: null,
  orderWeightError: '',
  orderValueHideError: false,
  orderWeightHideError: false,
  orderValueError: "",
  vId: 0
}
var mixinHandleOrderCreate = {
  props: {
    detailData: {
      type: Object,
      default: undefined
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    lstDistricts: {
      type: Array,
      default: undefined
    },
    districtDisableds: {
      type: Boolean,
      default: true,
    },
    lstCommunes: {
      type: Array,
      default: undefined
    },
    communeDisableds: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      timeOutTotalService: null,
      transportTypeConst: constants.transportType,
      transportType: constants.transportType.ship,
      isShowPickUpDateShipping: true,
      shopOrderSelected: "",
      listShop: [],
      listShopInit: [],
      listPickWorkShipGhtk: [],

      menuPickUpDate: false,
      pickUpDate: null,
      pickUpDateFm: null,
      formatDatePlaceholder: constants.formatDatePlaceholder,
      minAllowedDate: "",
      maxAllowedDate: "",

      timePicker: null,
      timePickerList: [constPickupTime.time1],
      timePickerDisabled: true,

      itemAddressDefault: null,
      itemAddressSelected: null,

      phone: "",
      maximumPhone: constants.maximumPhone,
      phoneRules: {
        required: true,
        regex: constants.regExp.phone,
      },

      fullName: "",
      maximumSmallText: constants.maximumSmallText,

      provinceOrderCodeSelected: null,
      provinceOrderNameSelected: null,
      lstDistrictOrder: [],
      districtOrderSelected: null,
      districtOrderSelectedName: null,
      districtDisabledOrder: true,
      lstCommuneOrder: [],
      communeOrderSelected: null,
      communeOrderSelectedName: null,
      communeDisabledOrder: true,

      customAddress: "",
      maximumMediumText: constants.maximumMediumText,
      maximumSizeLwH: constants.maximumSizeLwHFm,
      maximumSizeLWH: constants.maximumSizeLwH,
      maximumOrderWeight: constants.maximumOrderWeight,

      cod: "",
      codValueError: "",
      codValueHideError: false,
      maximumPriceFm: constants.maximumPriceValueFm,
      isShowTextBoxTypeOrderValue: false,
      insurance: constants.orderInsurance.no,
      receiverPhoneError: "",
      receiverPhoneHideError: false,
      wayBillRequire: "",
      statusError: false,
      minimumWaybill: constants.minimumWaybill,
      maximumWaybill: constants.maximumWaybill,
      waybillRules: {
        min: constants.minimumWaybill,
        max: constants.maximumWaybill,
        regex: constants.regExp.waybill,
      },
      waybillPrefix: constants.waybillPrefix,

      logisticServiceList: [{ name: "Tiêu chuẩn", value: 1 }],
      logisticService: null,
      serviceFeePayer: constants.serviceFeePayers.sender,
      serviceFeePayersConst: constants.serviceFeePayers,
      deliveryNoteSelected: "",
      lstDeliveryNote: [],
      transportFee: "0",
      insuranceFee: "0",
      totalFee: "0",
      totalRevenue: "0",
      totalService: "0",
      totalServiceFee: "0",
      showConfirmResetForm: false,
      textAcceptConfirmResetForm: "",
      message2: "",
      currentUser: JSON.parse(localStorage.getItem("CURRENT_USER")),
      menuPhone: false,
      listReceiver: [],
      lstProvince: [],
      listAllDistricts: [],
      listAllCommunes: [],
      receiverTimeout: null,
      fillDataFromChoosePhone: false,
      items: [],
      originItems: [],
      errorItems: [],
      receiverNameNull: false,
      receiverPhoneNull: false,
      receiverLocationNull: false,
      receiverAddressNull: false,
      noteNull: false,
      descriptionNull: false,
      weightNull: false,
      codValueNull: false,
      insuranceValueNull: false,
      paymentByNull: false,
      lstFeeTag: [],
      lstService: [],
      lstPartner: [],
      partnerSelect: null,
      serviceSelect: null,
      showNjv: false,
      code: null,
      checkFeeTmpLst: [],
      checkedFeeLst: [],
      lstServiceUserId: [],
      arrList: [],
      feeService: '0',
      valueService: 0,
      arrayListFee: [],
      listFeeTagMap: [],
      arrayAdress: [],
      checkItemFee: [],
      isDisableInsurance: false,
      ServiceFee: 0,
      showLoading: false,
      disableBtnCreate: false,
      showBack: true,
      pickWorkShip: null,
      reinforced: 0,
      lstOrder: [],
      totalItemWeight: 0,
      totalEstimateWeight: 0,
      totalOrderValue: 0,
      msgErrorService: null,
      showMsgErrorService: false,
      requireNote: false,
    };
  },
  computed: {
    isShowAddressCreateForOrder() {
      return (
        typeof this.itemAddressSelected === constants.undefined ||
        this.itemAddressSelected === null
      );
    },
    isInvalidCodValue() {
      return (
        parseFloat(StringUtils.replaceComma(this.cod)) >
        constants.maximumCodValue
      );
    },
    isCheckPhone() {
      if (this.itemAddressSelected !== undefined && this.itemAddressSelected !== null) {
        return this.phone === this.itemAddressSelected.phone
      } else {
        return false;
      }
    },
    isInvalidAction() {
      return (
        this.isInvalidCodValue || this.isWeightError || this.isCheckPhone
      );
    },
    checkBtnCreate() {
      if (this.lstOrder.length > 1) {
        return false
      }
      return true
    }
  },
  watch: {
    pickUpDate(val) {
      this.pickUpDateFm = this.formatDate(this.pickUpDate);
    },
    totalEstimateWeight() {
      this.calculateShipFee()
    },
    totalItemWeight() {
      this.calculateShipFee()
    },
    totalOrderValue() {
      this.lstFeeTag.forEach((item) => {
        item.isDisableFeeTag = false
        if (item.requireValue && this.totalOrderValue && parseFloat(item.requireValue) <= parseFloat(this.totalOrderValue)) {
          item.isDisableFeeTag = true
          if (!this.checkedFeeLst.includes(item.id)) {
            this.checkedFeeLst.push(item.id)
          }
          this.checkCode();
        }
      })
    }
  },
  created() {
    this.textAcceptConfirmResetForm = this.$t("re_type");
    this.calculateMinMaxAllowedDate();
    this.logisticService = this.logisticServiceList[0];
    const item = this.getInitItemLocal()
    this.lstOrder.push({ ...item }, { ...item })
  },
  methods: {
    addItemOrder() {
      const item = this.getInitItemLocal()
      this.lstOrder.push({ ...item })
    },
    onDeleteItem(idx) {
      this.lstOrder.splice(idx, 1)
    },
    getInitItemLocal() {
      return { ...initItemOrder }
    },
    onBlurValue(evt, idx, item) {
      var estWeight = 0
      this.totalEstimateWeight = 0
      this.totalItemWeight = 0
      this.totalOrderValue = 0
      this.lstOrder.forEach((it, index) => {
        estWeight = StringUtils.numStrToFloat(this.computedEstimateWeight(it.length, it.width, it.height))
        this.totalEstimateWeight += estWeight
      })
      const sumOrderWeight = this.lstOrder.reduce((accumulator, object) => {
        return accumulator + StringUtils.numStrToInt(object.weight);
      }, 0);
      const sumOrderValue = this.lstOrder.reduce((accumulator, object) => {
        return accumulator + StringUtils.numStrToInt(object.orderValue);
      }, 0);
      this.totalItemWeight = sumOrderWeight
      this.totalOrderValue = sumOrderValue
      this.checkValidateWeight(item)
      this.computedEstimateWeight(item)
    },
    checkValidateWeight(item) {
      if (this.totalItemWeight > 300) {
        item.orderWeightError = `${this.$t("msg_total_weight_error")}`;
        item.orderWeightHideError = true
      } else {
        item.orderWeightError = "";
        item.orderWeightHideError = false
      }
    },
    getOrderValueGhtk(val) {
      if (this.partnerSelect === constants.partnerGroup.ghtk) {
        if (val >= constants.maximumInsurance) {
          this.checkedFeeLst.push(constants.feeTag.insurance);
          this.lstFeeTag.forEach((id) => {
            id.isDisableInsurance = false
            if (id.id === constants.feeTag.insurance) {
              id.isDisableInsurance = true
            }
          })
          this.checkFeeTmpLst.push(constants.feeTag.insurance);
        } else {
          this.checkedFeeLst = this.checkedFeeLst.filter((feeCode) => feeCode !== constants.feeTag.insurance);
          this.checkFeeTmpLst = this.checkFeeTmpLst.filter((feeCode) => feeCode !== constants.feeTag.insurance);
        }
      }
    },
    computedEstimateWeight(length, width, height) {
      if (length && width && height) {
        if (this.partnerSelect === constants.partnerGroup.ninjaVan) {
          const weightTranfer =
            (StringUtils.numStrToInt(length) *
              StringUtils.numStrToInt(width) *
              StringUtils.numStrToInt(height)) /
            6000;
          return StringUtils.formatDecimal(weightTranfer);
        } else {
          if (this.serviceSelect === constants.servicesShipping.over) {
            const weightTranfer =
              (StringUtils.numStrToInt(length) *
                StringUtils.numStrToInt(width) *
                StringUtils.numStrToInt(height)) /
              4000;
            return StringUtils.formatDecimal(weightTranfer);
          } else {
            const weightTranfer =
              (StringUtils.numStrToInt(length) *
                StringUtils.numStrToInt(width) *
                StringUtils.numStrToInt(height)) /
              6000;
            return StringUtils.formatDecimal(weightTranfer);
          }
        }
      }
      return 0;
    },
    // async findForUserId() {
    //   const { status, data } = await OrderService.findForUserId(this.shopOrderSelected);
    //   if (status === constants.statusCode.ok && data) {
    //     this.lstServiceUserId = data;
    //     this.lstPartner = []
    //     this.lstServiceUserId.forEach((it) => {
    //       this.lstPartner.push({
    //         value: constants.partnerGroup.ghtk,
    //         name: this.$t("ghtk")
    //       })
    //     })
    //   } else if (StorageService.checkResponseExpried(status, data)) {
    //     this.showConfirmTokenExpried = true;
    //   }
    // },
    async onOrderCreate() {
      var valid = this.$refs.observer.validate();
      if (valid) {
        this.showLoading = true;
        this.disableBtnCreate = true;
        const pars = this.bindRequestPars();
        const { status, data } = await OrderService.createMultiPackages(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok) {
          this.toggleDialogNoti({
            state: true,
            type: constants.typeAlert.success,
            msg: this.$t("success_order_create"),
          });
          setTimeout(() => {
            this.toggleDialogNoti();
            this.$router.push(routePaths.ORDERS);
          }, 2500);
        } else if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true;
        } else {
          let msgNoti = "";
          if (data.message === constants.errorValidation) {
            var jsonObj = data.details;
            var keys = Object.keys(jsonObj);
            for (var i = 0; i < keys.length; i++) {
              if (i < keys.length - 1) {
                msgNoti += jsonObj[keys[i]] + ",";
              } else {
                msgNoti += jsonObj[keys[i]];
              }
            }
          } else {
            msgNoti = data.message;
          }

          this.toggleDialogNoti({ state: true, msg: msgNoti });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, 2500);
          this.disableBtnCreate = false
        }
      }
    },
    bindRequestPars() {
      const pars = {
        custId: this.shopOrderSelected,
        partnerId: this.partnerSelect,
        transportType: this.transportType,
        addressId: this.itemAddressSelected.id,
        receiverName: this.fullName.trim(),
        receiverPhone: this.phone.trim(),
        receiverProvinceCode: parseInt(this.provinceOrderCodeSelected),
        receiverProvinceName: this.provinceOrderNameSelected,
        receiverDistrictCode: parseInt(this.districtOrderSelected),
        receiverDistrictName: this.districtOrderSelectedName,
        receiverCommuneCode: parseInt(this.communeOrderSelected),
        receiverCommuneName: this.communeOrderSelectedName,
        receiverAddress: this.customAddress,
        note: this.deliveryNoteSelected,
        paymentBy: this.serviceFeePayer,
        shipValue: StringUtils.numStrToFloat(this.transportFee),
        reqPackages: this.getRequestPackage()
      };
      const arrTag = this.checkedFeeLst.filter((item, index) => {
        return this.checkedFeeLst.indexOf(item) === index
      })
      if (this.partnerSelect === constants.partnerGroup.ninjaVan) {
        pars["serviceId"] = constants.servicesShipping.normal
      } else {
        if (this.serviceSelect === constants.servicesShipping.standard) {
          pars["serviceId"] = constants.servicesShipping.standard;
        } else {
          pars["serviceId"] = this.serviceSelect;
        }
      }
      pars["tags"] = arrTag.toString()
      if (this.wayBillRequire.trim()) {
        pars["deliveryCode"] = this.wayBillRequire.trim();
      }
      if (this.transportType === constants.transportType.ship) {
        pars["pickDate"] = this.formatDateYmd(this.pickUpDate)
        pars["pickWorkShift"] = this.pickWorkShip
      }

      if (this.cod) {
        pars["codValue"] = StringUtils.numStrToFloat(this.cod);
      }

      return pars;
    },
    getRequestPackage() {
      const rqPars = []
      this.lstOrder.forEach((it, index) => {
        const item = this.mappingRequestItem(it, index)
        rqPars.push(item)
      })
      return rqPars
    },
    mappingRequestItem(itemOrder, idOrder) {
      const item = {}
      item['description'] = itemOrder.description
      item['length'] = StringUtils.numStrToFloat(itemOrder.length)
      item['height'] = StringUtils.numStrToFloat(itemOrder.height)
      item['width'] = StringUtils.numStrToFloat(itemOrder.width)
      item['weight'] = StringUtils.numStrToFloat(itemOrder.weight)
      item['orderValue'] = StringUtils.numStrToFloat(itemOrder.orderValue)
      item['estimateWeight'] = StringUtils.numStrToFloat(this.computedEstimateWeight(itemOrder.length, itemOrder.width, itemOrder.height))
      item['orderNo'] = idOrder + 1
      return item
    },
    async getListDeliveryNote() {
      this.showLoading = false;
      const { status, data } = await CommonService.getListDeliveryNote();
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.lstDeliveryNote = data;
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    async getLastTransportType() {
      const { status, data } = await OrderService.getLastTransportType(
        this.currentUser.id
      );
      if (status === constants.statusCode.ok && data) {
        this.transportType = data;
        if (this.transportType === constants.transportType.individual) {
          this.isShowPickUpDateShipping = false;
        } else {
          this.isShowPickUpDateShipping = true;
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    parsePickupTimeFrom() {
      const timeFrom = this.timePicker.from;
      return `${this.pickUpDate}T${timeFrom}`;
    },
    parsePickupTimeTo() {
      const timeTo = this.timePicker.to;
      return `${this.pickUpDate}T${timeTo}`;
    },
    async getAddressDefault() {
      this.showLoading = false;
      const { status, data } = await UserService.addressGetDefault(this.shopOrderSelected);
      this.showLoading = false;
      if (status === constants.statusCode.ok && data !== null) {
        this.itemAddressDefault = data;
        if (this.shopOrderSelected) {
          this.itemAddressSelected = data;
          this.calculateShipFee();
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    formatDate(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate);
    },
    formatDateYmd(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDateDmy);
    },
    parseDate(date) {
      return DateTimeUtils.parseDate(date);
    },
    async getShops() {
      const pars = {
        poId: null
      }
      const { status, data } = await UserService.getShops(pars);
      if (status === constants.statusCode.ok && data) {
        this.listShop = data;
        this.listShopInit = data;
      }
    },
    async getAllPickWorkShip() {
      const { status, data } = await OrderService.getAllPickWorkShip();
      if (status === constants.statusCode.ok && data) {
        this.listPickWorkShipGhtk = data;
      }
    },
    searchShopOrder(val) {
      this.listShop = this.listShopInit.filter((item) =>
        item.unsignedName
          .toLowerCase()
          .includes(this.removeVietnameseTones(val).toLowerCase())
      );
    },
    async onChangeTransportType(val) {
      this.isShowPickUpDateShipping = Boolean(val === constants.transportType.ship)
      if (val === constants.transportType.ship) {
        this.calculateMinMaxAllowedDate();
      }
    },
    calculateMinMaxAllowedDate() {
      const currDate = moment().format(constants.formatDateYmd);
      const hours = moment().format("HH");
      if (hours < constants.limitHourOrderCreate) {
        this.minAllowedDate = currDate;
      } else {
        var nextDate = DateTimeUtils.getTomorrow();
        if (this.isDayOff(nextDate) && this.partnerSelect === constants.partnerGroup.ninjaVan) {
          nextDate = DateTimeUtils.getNextDateByAddDay(nextDate, 1);
        } else {
          nextDate = DateTimeUtils.getNextDateByAddDay(nextDate, 0);
        }

        this.minAllowedDate = nextDate;
      }
      this.maxAllowedDate = this.getMaxAllowedDate(this.minAllowedDate);
    },
    getMaxAllowedDate(minDate) {
      var dayOfWeek = moment(minDate).day();
      var dayByPartner = null
      if (this.partnerSelect === constants.partnerGroup.ninjaVan) {
        dayByPartner = 7 - dayOfWeek > 2
      } else {
        dayByPartner = 7 - dayOfWeek >= 2
      }
      if (dayByPartner) {
        return DateTimeUtils.getNextDateByAddDay(
          minDate,
          constants.numberDateAllowedOrderCreate
        );
      } else {
        return DateTimeUtils.getNextDateByAddDay(
          minDate,
          this.partnerSelect === constants.partnerGroup.ninjaVan ? constants.numberDateAllowedOrderCreate + 1 : constants.numberDateAllowedOrderCreate
        );
      }
    },
    allowedDates(date) {
      return !this.isDayOff(date);
    },
    isDayOff(date) {
      const dateCompare = new Date(date).getDay();
      return dateCompare === 0;
    },
    onInputPickUpDate(val) {
      this.menuPickUpDate = false;
      this.timePickerDisabled = false;
    },
    isToDay(val) {
      var inputDate = new Date(val);
      // Get today's date
      var todaysDate = new Date();
      return inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0);
    },
    isOrderWeight(event) {
      return FormUtils.isDecimal(event);
    },
    async onChangeProvinceOrder(val) {
      if (parseInt(this.provinceOrderCodeSelected) !== val) {
        this.provinceOrderCodeSelected = val;
        if (this.lstProvince.length) {
          this.lstProvince.forEach((item) => {
            if (item.code === val) {
              this.provinceOrderNameSelected = item.fullName;
            }
          });
        }
        this.clearDistrict();
        this.clearCommune();
        this.fillDataFromChoosePhone = false;
        await Promise.all([this.getDistrictByProvinceCodeOrder(val), this.calculateShipFee()]);
        this.$refs.inputProvinceOrder.blur();
        this.$refs.inputDistrictOrder.focus();
      }
    },
    clearDistrict() {
      this.districtOrderSelected = null;
      this.districtOrder ? this.$refs.observer.refs.districtOrder.reset() : '';
      this.lstDistrictOrder = [];
      this.districtDisabledOrder = true;
    },
    clearCommune() {
      this.communeOrderSelected = null;
      this.communeOrder ? this.$refs.observer.refs.communeOrder.reset() : '';
      this.lstCommuneOrder = [];
      this.communeDisabledOrder = true;
    },
    // list quan/huyen
    async getDistrictByProvinceCodeOrder(code) {
      this.showLoading = false;
      const pars = {
        provinceCode: code,
      };
      const { data } = await UserService.findDistrictByProvinceCode(pars);
      this.showLoading = false;
      if (data != null && data.length > 0 && !this.fillDataFromChoosePhone) {
        this.lstDistrictOrder = data;
        this.lstDistricts = data;
        this.districtDisableds = false;
        this.districtDisabledOrder = false;
      } else if (this.provinceOrderCodeSelected && this.fillDataFromChoosePhone) {
        this.lstDistrictOrder = data;
        this.lstDistricts = data;
        this.lstDistrictOrder.forEach((item) => {
          if (item.fullName === this.districtOrderSelectedName) {
            this.districtOrderSelected = item.code;
            this.districtOrderSelectedName = item.name;
            this.districtDisabledOrder = false;
            this.getCommuneByDistrictCodeOrder(this.districtOrderSelected);
          }
        });
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    async onChangeDistrictOrder(val) {
      this.districtOrderSelected = val;
      if (this.lstDistrictOrder.length) {
        this.lstDistrictOrder.forEach((item) => {
          if (item.code === val) {
            this.districtOrderSelectedName = item.fullName;
          }
        });
      }
      this.clearCommune();
      this.fillDataFromChoosePhone = false;
      await this.getCommuneByDistrictCodeOrder(val);
      this.$refs.inputDistrictOrder.blur();
      this.$refs.inputCommuneOrder.focus();
    },
    async onChangeCommuneOrder(val) {
      if (this.lstCommuneOrder.length) {
        this.lstCommuneOrder.forEach((item) => {
          if (item.code === val) {
            this.communeOrderSelectedName = item.fullName;
          }
        });
      }
    },
    // list phuong/xa
    async getCommuneByDistrictCodeOrder(code) {
      this.showLoading = false;
      const pars = {
        districtCode: code,
      };
      const { data } = await UserService.findCommuneByDistrictCode(pars);
      this.showLoading = false;
      if (data != null && data.length > 0 && !this.fillDataFromChoosePhone) {
        this.lstCommuneOrder = data;
        this.lstCommunes = data;
        this.communeDisableds = false;
        this.communeDisabledOrder = false;
      } else if (this.districtOrderSelected && this.fillDataFromChoosePhone) {
        this.lstCommuneOrder = data;
        this.lstCommuneOrder.forEach((item) => {
          if (item.fullName === this.communeOrderSelectedName) {
            this.communeOrderSelected = item.code;
            this.communeOrderSelectedName = item.name;
            this.communeDisabledOrder = false;
          }
        });
      }
    },
    onPastePhone(evt) {
      const val = FormUtils.isOnPasteOnlyNumber(evt);
      if (val !== null) {
        this.phone = val;
        this.inputPhone(this.phone);
      }
    },
    async inputPhone(val) {
      val = val + "";
      if (this.itemAddressSelected !== undefined && this.itemAddressSelected !== null) {
        if (val === this.itemAddressSelected.phone) {
          const msg = this.$t("duplicate_phone_sender_receiver");
          this.receiverPhoneError = msg
          this.receiverPhoneHideError = true;
        } else {
          this.receiverPhoneError = ''
          this.receiverPhoneHideError = false;
        }
      } else {
        this.receiverPhoneError = ''
        this.receiverPhoneHideError = false;
      }
      if (val.length > 3) {
        clearTimeout(this.receiverTimeout);
        this.receiverTimeout = setTimeout(async() => {
          const pars = {
            custId: this.shopOrderSelected,
            phone: val,
          };
          const { status, data } = await OrderService.getReceiverInfoByPhone(
            pars
          );
          if (status === constants.statusCode.ok && data) {
            this.menuPhone = true;
            this.listReceiver = data;
          }
        }, 1000);
      } else {
        this.listReceiver = [];
      }
    },
    async choosePhone(val) {
      this.fillDataFromChoosePhone = true;
      this.phone = val;
      if (this.phone === this.itemAddressSelected.phone) {
        const msg = this.$t("duplicate_phone_sender_receiver");
        this.receiverPhoneError = msg
        this.receiverPhoneHideError = true;
      } else {
        this.receiverPhoneError = ''
        this.receiverPhoneHideError = false;
      }
      this.listReceiver.forEach((item) => {
        if (item.phone === val) {
          this.fullName = item.name;
          this.provinceOrderCodeSelected = item.provinceCode;
          this.provinceOrderNameSelected = item.provinceName;
          this.districtOrderSelectedName = item.districtName;
          this.communeOrderSelectedName = item.communeName;
          this.customAddress = item.address;
        }
      });
      await this.getDistrictByProvinceCodeOrder(this.provinceOrderCodeSelected);
    },
    onInputCOD(val) {
      this.cod = StringUtils.formatNumber(val);
      if (this.detailData) {
        this.detailData.codValue = StringUtils.formatNumber(val);
      }
      this.calculateFees();
      this.checkValidCodValue();
    },
    checkValidCodValue() {
      if (
        parseFloat(StringUtils.replaceComma(this.cod)) >
        constants.maximumCodValue
      ) {
        const msg = `${this.$t("cod")} ${this.$t(
          "maximum"
        )} ${StringUtils.formatNumber(constants.maximumOrderValue)} ${this.$t(
          "vnd_unit"
        )}`;
        this.codValueError = msg;
        this.codValueHideError = true;
      } else {
        this.codValueError = "";
        this.codValueHideError = false;
      }
    },
    onPasteCOD(evt) {
      const val = FormUtils.isOnPasteOnlyNumberAndMaximum(
        evt,
        constants.maximumPriceValue
      );
      if (val !== null) {
        this.cod = StringUtils.formatNumber(val);
        this.orderDetailData.codValue = StringUtils.formatNumber(val);
        this.orderDetailData.orderValue = StringUtils.formatNumber(val);
        this.checkValidCodValue();
      }
    },
    // on change dich vu van chuyen tao don le
    async onChangeGetServiceAll(val) {
      this.checkedFeeLst = []
      this.totalServiceFee = 0
      const service = this.lstPartner.find((it) => it.partnerId === val)
      if (service) {
        this.serviceSelect = service.serviceId
        this.transportFee = service.shipFee
        this.requireNote = service.requireNote
        this.calculateFees();
        await this.getEstablishServiceByPartner(this.partnerSelect)
      }
    },
    // danh sach dich vu theo don vi van chuyen
    async getEstablishServiceByPartner(par) {
      try {
        this.checkedFeeLs = []
        const response = await OrderService.getEstablishServiceByPartner(par)
        this.lstFeeTag = response.data
        this.lstFeeTag.forEach((item) => {
          item.isDisableFeeTag = false
          if (item.requireValue && this.totalOrderValue && parseFloat(item.requireValue) <= parseFloat(this.totalOrderValue)) {
            item.isDisableFeeTag = true
            this.checkedFeeLst.push(item.id)
            this.checkCode();
          }
        })
      } catch (error) {
        console.log(error);
      }
    },
    checkCode() {
      clearTimeout(this.timeOutTotalService);
      this.timeOutTotalService = setTimeout(async() => {
        const payload = {
          "partnerId": this.partnerSelect,
          "serviceFeeIds": this.checkedFeeLst,
          "orderValue": this.totalOrderValue ? parseInt(this.totalOrderValue) : 0,
          "shipValue": this.transportFee
        }
        const { status, data } = await OrderService.calculateServicefee(payload)
        if (status === constants.statusCode.ok && data) {
          this.totalServiceFee = data.reduce((total, fee) => total + fee.value, 0);
        }
        this.calculateFees();
      }, 800)
    },
    checkValidInsurance(val) {
      if (val === "Y" || val === "y" || val.toLowerCase() === "yes") {
        return true;
      } else {
        return false;
      }
    },
    changeShop() {
      if (this.shopOrderSelected !== null) {
        this.getAddressDefault()
        this.calculateShipFee();
      }
    },
    async calculateShipFee() {
      this.resetShippingInformation();
      if (this.checkValidShipFee()) {
        const pars = this.bindCalculateShipFeeRequestPars();
        const { status, data } = await OrderService.multiCalculateShipfee(pars);
        if (status === constants.statusCode.ok) {
          this.lstPartner = data
        }
      }
    },
    formatCurrency(val) {
      return val.toLocaleString("it-IT", {
        style: "currency",
        currency: "VND",
      })
    },
    resetShippingInformation() {
      this.totalFee = "0"
      this.totalServiceFee = "0"
      this.transportFee = "0"
      this.partnerSelect = null
      this.serviceSelect = null
      this.lstService = []
      this.lstFeeTag = []
      this.lstPartner = []
      this.checkedFeeLst = []
      this.calculateFees()
    },
    checkValidShipFee() {
      if (!this.itemAddressSelected) return false;

      if (!this.shopOrderSelected) return false;

      if (!this.provinceOrderCodeSelected) return false;

      if (this.estimateWeight <= 0) return false

      if (this.totalItemWeight <= 0) return false

      return true;
    },
    bindCalculateShipFeeRequestPars() {
      const pars = {
        custId: this.shopOrderSelected,
        transportType: this.transportType,
        addressId: this.itemAddressSelected.id,
        receiverProvinceCode: this.provinceOrderCodeSelected,
        weight: StringUtils.numStrToFloat(this.totalItemWeight),
        estimateWeight: StringUtils.numStrToFloat(this.totalEstimateWeight),
      };
      return pars;
    },
    calculateFees() {
      this.totalFee = 0
      if (this.transportFee && !isNaN(parseFloat(this.transportFee))) {
        this.totalFee += parseFloat(this.transportFee)
      }
      if (this.totalServiceFee && !isNaN(parseFloat(this.totalServiceFee))) {
        this.totalFee += parseFloat(this.totalServiceFee)
      }
      this.calculateTotalRevenue()
    },
    calculateTotalRevenue() {
      this.totalRevenue = 0;
      if (this.serviceFeePayer === this.serviceFeePayersConst.receiver) {
        if (this.transportFee && !isNaN(parseFloat(this.transportFee))) {
          this.totalRevenue += parseFloat(this.transportFee)
        }
        if (this.totalServiceFee && !isNaN(parseFloat(this.totalServiceFee))) {
          this.totalRevenue += parseFloat(this.totalServiceFee)
        }
        if (this.cod && !isNaN(parseFloat(this.cod.replaceAll(",", "")))) {
          this.totalRevenue += parseFloat(this.cod.replaceAll(",", ""))
        }
        return
      }
      if (this.cod && !isNaN(parseFloat(this.cod.replaceAll(",", "")))) {
        this.totalRevenue += parseFloat(this.cod.replaceAll(",", ""))
      }
    },
    reType() {
      this.onShowConfirmResetForm();
    },
    onShowConfirmResetForm() {
      this.typeNoti = constants.typeAlert.warning;
      this.message = this.$t("all_info_deleted");
      this.message2 = this.$t("are_you_re_enter");
      this.showConfirmResetForm = true;
    },
    onCancelConfirmResetForm() {
      this.showConfirmResetForm = false;
    },
    onAcceptConfirmResetForm() {
      this.showConfirmResetForm = false;
      this.resetForm();
      this.$refs.inputInfoOrder.resetItem()
    },
    onChangeServiceFeePayer(val) {
      this.calculateFees();
    },
    getTextTruncateView(val, size) {
      return getTextTruncate(val, size);
    },
    removeVietnameseTones(str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      str = str.replace(/Đ/g, "D");
      // Some system encode vietnamese combining accent as individual utf-8 characters
      // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
      // Remove extra spaces
      // Bỏ các khoảng trắng liền nhau
      str = str.replace(/ + /g, " ");
      str = str.trim();
      // Remove punctuations
      // Bỏ dấu câu, kí tự đặc biệt
      str = str.replace(
        /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
        " "
      );
      return str;
    },
    resetForm() {
      this.pickUpDate = null;
      this.pickUpDateFm = null;
      this.timePicker = constPickupTime.time1;
      // this.timePickerList = []
      this.timePickerDisabled = true;
      this.calculateMinMaxAllowedDate();
      this.itemAddressSelected = this.itemAddressDefault;
      this.phone = "";
      this.fullName = "";
      this.provinceOrderCodeSelected = null;
      this.lstDistrictOrder = [];
      this.districtOrderSelected = null;
      this.districtOrderSelectedName = null;
      this.districtDisabledOrder = true;
      this.lstCommuneOrder = [];
      this.communeOrderSelected = null;
      this.communeOrderSelectedName = null;
      this.communeDisabledOrder = true;
      this.customAddress = "";
      this.cod = "";
      this.isShowTextBoxTypeOrderValue = false;
      this.insurance = constants.orderInsurance.no;
      this.serviceFeePayer = constants.serviceFeePayers.sender;
      this.deliveryNoteSelected = "";
      this.transportFee = "0";
      this.insuranceFee = "0";
      this.totalFee = "0";
      this.totalRevenue = "0";
      this.valueService = "0"
      this.codValueHideError = false
      this.codValueError = ""
      this.receiverPhoneHideError = false
      this.receiverPhoneError = ""
      this.shopOrderSelected = null
      this.partnerSelect = null
      this.serviceSelect = null
      this.lstFeeTag = []
      this.$refs.observer.reset();
    },
  },
};

export default mixinHandleOrderCreate;
